<template>
  <div class="test-drive-controls">
    <TabPanel
      v-model:active-tab-id="activeTabId"
      :tabs="Object.values(TestDriveTabs)" />
    <div class="flex items-center gap-[0.5rem]">
      <AppInput
        v-model="searchTerm"
        id="test-drive-table--search-term"
        class="max-w-[12rem]"
        theme="rounded"
        label="Search"
        :disabled="isLoading" />
      <AppDatePicker
        v-model="searchStartDate"
        theme="rounded"
        class="max-w-[12rem]"
        placeholder="MM/DD/YYYY"
        :enable-time-picker="false"
        :disabled="isLoading" />
      <b>To</b>
      <AppDatePicker
        v-model="searchEndDate"
        theme="rounded"
        class="max-w-[12rem]"
        placeholder="MM/DD/YYYY"
        :enable-time-picker="false"
        :min-date="searchStartDate"
        :disabled="isLoading" />
    </div>
  </div>
  <table class="table">
    <thead>
      <th @click="sortColumn('name')">
        <div class="column-container">
          Name
          <IconSortColumn :status="checkSortIcon('name')" />
        </div>
      </th>
      <th
        @click="sortColumn('vin')"
        colspan="4">
        <div class="column-container">
          Vehicle
          <IconSortColumn :status="checkSortIcon('vin')" />
        </div>
      </th>
      <th @click="sortColumn('startTime')">
        <div class="column-container">
          Start Time
          <IconSortColumn :status="checkSortIcon('startTime')" />
        </div>
      </th>
      <th @click="sortColumn('endTime')">
        <div class="column-container">
          End Time
          <IconSortColumn :status="checkSortIcon('endTime')" />
        </div>
      </th>
      <th @click="sortColumn('status')">
        <div class="column-container">
          Status
          <IconSortColumn :status="checkSortIcon('status')" />
        </div>
      </th>
      <th>
        <div class="column-container">
          Edit
        </div>
      </th>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in table.paginatedItems.value"
        :key="index">
        <td>{{ `${item.firstName} ${item.lastName}` }}</td>
        <td>{{ item.vehicle.modelYear }}</td>
        <td>{{ item.vehicle.trim }}</td>
        <td>{{ item.vehicle.color }}</td>
        <td>{{ item.vehicle.vin }}</td>
        <td>{{ format(new Date(item.startTime), 'MM/dd/yyyy h:mm a') }}</td>
        <td>{{ format(new Date(item.endTime), 'MM/dd/yyyy h:mm a') }}</td>
        <td>{{ item.status }}</td>
        <td class="text-center">
          <button
            class="uppercase"
            type="button"
            @click="editTestDrive(item)">
            Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <TablePagination
    v-model:items-per-page="table.itemsPerPage.value"
    :current-page="table.currentPage.value"
    :total-of-pages="table.totalOfPages.value"
    :total-of-items="filteredTestDriveList.length"
    :app-insights-config="paginationAppInsightsConfig"
    @previous-page="table.moveToPreviousPage"
    @next-page="table.moveToNextPage"
    @set-page="table.currentPage.value = $event" />
  <TestDriveModal
    :is-open="!!selectedTestDrive"
    :test-drive="selectedTestDrive"
    @test-drive-updated="loadTestDrives"
    @close="selectedTestDrive = null" />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';
import { format, isAfter, isBefore, set } from 'date-fns';
import { useTable } from '@/composables/table';
import { TestDriveTabs } from '@/constants/testDrive';
import AppInput from '../form/AppInput.vue';
import AppDatePicker from '../form/AppDatePicker.vue';
import TabPanel from '../TabPanel.vue';
import TablePagination from '../TablePagination.vue';
import TestDriveModal from '../modals/TestDriveModal.vue';
import IconSortColumn from '../icons/IconSortColumn.vue';
import { appInsights } from '../../../application-insights';

const store = useStore();
const snackbar = useSnackbar();

const testDriveList = computed(() => store.getters['testDrive/testDriveList']);
const isLoading = computed(
  () => store.getters['testDrive/isLoadingTestDrives']
);

// Active tab
const activeTabId = ref(TestDriveTabs.SHOW_ALL.id);

// Selected dealership
const dealership = computed(() => store.getters['auth/dealership']);

const paginationAppInsightsConfig = ref({
  name: 'TestDriveRecordsTableActions',
  dealershipName: dealership.value.dealerName,
  page: 'dashboard',
});
// Search
const searchTerm = ref('');
const searchStartDate = ref('');
const searchEndDate = ref('');

// Sort Table
const sortTable = ref({ sortBy: 'startTime', sortDesc: false });

watch(
  () => searchStartDate.value,
  () => {
    if (
      !!searchStartDate.value &&
      !!searchEndDate.value &&
      isBefore(new Date(searchEndDate.value), new Date(searchStartDate.value))
    ) {
      searchEndDate.value = '';
    }
  }
);

const debounceTerm = ref(null);

watch(
  () => [
    activeTabId.value,
    searchTerm.value,
    searchStartDate.value,
    searchEndDate.value,
  ],
  (newValue, oldValue) => {
    const [newActiveTab, newSearchTerm, newStartDate, newEndDate] = newValue;
    const [oldActiveTab, oldSearchTerm, oldStartDate, oldEndDate] = oldValue;

    newActiveTab !== oldActiveTab &&
      trackEventOnTable('ActiveTab', newActiveTab);

    if (newSearchTerm !== oldSearchTerm) {
      if (debounceTerm.value) clearTimeout(debounceTerm.value);
      debounceTerm.value = setTimeout(
        () => trackEventOnTable('SearchTerm', newSearchTerm),
        1500
      );
    }

    newStartDate !== oldStartDate &&
      trackEventOnTable('StartDate', newStartDate);
    newEndDate !== oldEndDate && trackEventOnTable('EndDate', newEndDate);
  }
);

const editTestDrive = (item) => {
  selectedTestDrive.value = item;
  trackEventOnTable('Edit');
};

const trackEventOnTable = (action, value = '') => {
  appInsights.trackEvent({
    name: 'TestDriveRecordsTableActions',
    properties: {
      dealership: dealership.value.dealerName,
      action: action,
      page: 'dashboard',
      actionValue: value,
    },
  });
}

function itemExistsInSearch(item) {
  const term = searchTerm.value.toLowerCase();
  const {
    firstName,
    lastName,
    vehicle: { color, modelYear, trim, vin },
  } = item;

  return (
    `${firstName} ${lastName}`.toLowerCase().includes(term) ||
    `${color} ${modelYear} ${trim} ${vin}`.toLowerCase().includes(term)
  );
}

const filteredTestDriveList = computed(() =>
  testDriveList.value.filter((item) => {
    if (
      activeTabId.value !== TestDriveTabs.SHOW_ALL.id &&
      item.status !== activeTabId.value
    ) {
      return false;
    }

    const startTime = set(new Date(item.startTime), { hours: 0, minutes: 0 });
    const endTime = set(new Date(item.endTime), { hours: 0, minutes: 0 });
    const searchStartTime = set(new Date(searchStartDate.value), {
      hours: 0,
      minutes: 0,
    });
    const searchEndTime = set(new Date(searchEndDate.value), {
      hours: 0,
      minutes: 0,
    });

    if (
      (!!searchStartDate.value && isBefore(startTime, searchStartTime)) ||
      (!!searchEndDate.value && isAfter(endTime, searchEndTime))
    ) {
      return false;
    }

    if (!!searchTerm.value && !itemExistsInSearch(item)) {
      return false;
    }

    return true;
  })
);

// Table composable
const table = useTable(filteredTestDriveList);

// Selected test drive
const selectedTestDrive = ref(null);

// Load test drives
async function loadTestDrives() {
  store.commit('testDrive/setIsLoadingTestDrives', true);

  try {
    await store.dispatch('testDrive/loadTestDrives');
  } catch (error) {
    snackbar.add({
      type: 'error',
      text: error.message,
    });
  } finally {
    store.commit('testDrive/setIsLoadingTestDrives', false);
  }
}

function sortColumn(columnName) {
  if (sortTable.value.sortBy === columnName) {
    sortTable.value.sortDesc = !sortTable.value.sortDesc;
  } else {
    sortTable.value.sortBy = columnName;
    sortTable.value.sortDesc = false;
  }

  trackEventOnTable('SortTable', sortTable.value);

  const sorted = testDriveList.value.slice().sort((a, b) => {
    const modifier = sortTable.value.sortDesc ? -1 : 1;
    if (sortTable.value.sortBy === 'vin') {
      return modifier * (a.vehicle['vin'] > b.vehicle['vin'] ? 1 : -1);
    }
    if (sortTable.value.sortBy === 'name') {
      return (
        modifier *
        (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
          ? 1
          : -1)
      );
    }
    return (
      modifier *
      (a[sortTable.value.sortBy] > b[sortTable.value.sortBy] ? 1 : -1)
    );
  });

  store.commit('testDrive/setTestDriveList', sorted);
}

function checkSortIcon(columnName) {
  if (sortTable.value.sortBy !== columnName) return 'sort';
  const sortOrder = !sortTable.value.sortDesc ? 'sorted-asc' : 'sorted-desc';
  return sortOrder;
}

loadTestDrives();
</script>

<style scoped>
.test-drive-controls {
  @apply flex items-center justify-between flex-wrap gap-[1rem] mb-[2rem];
}
.table {
  @apply w-full uppercase leading-none;
}
.table thead,
.table tbody tr {
  @apply border border-[#CFCFCF];
}
.table thead th {
  cursor: pointer;

  @apply bg-[#F3F3F3]
  text-ford-gray-dark
  p-[1rem]
  text-left;
}
.column-container {
  @apply flex gap-2 text-ford-black-light;
}
.table tbody::before {
  content: '';
  @apply block
  w-full
  h-[0.625rem];
}
.table tbody tr:not(:last-child) {
  @apply border-b-[#EDEDED];
}
.table tbody td {
  @apply text-[0.875rem]
  p-[1rem];
}
</style>
