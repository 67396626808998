<template>
  <div
    class="group"
    :class="{ 'is-invalid': dirty && !!errorMessage }"
    :data-invalid="dirty && !!errorMessage"
    :data-theme="theme">
    <template v-if="fixedLabel">
      <label :for="id ? id : null">{{ inputPlaceholder }}</label> <br />
    </template>
    <template v-if="type === 'textarea'">
      <textarea
        v-model="inputValue"
        class="input input--textarea"
        :class="customClass"
        :id="id ? id : null"
        :disabled="disabled"
        :aria-label="label"
        :placeholder="!fixedLabel ? inputPlaceholder : ''"
        @input="handleChange($event.target.value)"
        @blur="$emit('blur')" />
    </template>
    <input
      v-else-if="mask"
      :id="id ? id : null"
      ref="input"
      class="input"
      :class="customClass"
      type="text"
      :disabled="disabled"
      :aria-label="label"
      :placeholder="!fixedLabel ? inputPlaceholder : ''"
      @blur="$emit('blur'), handleChange(imaskInstance.unmaskedValue)" />
    <input
      v-else
      v-model="inputValue"
      :id="id ? id : null"
      class="input"
      :class="customClass"
      :type="type"
      :disabled="disabled"
      :aria-label="label"
      :placeholder="!fixedLabel ? inputPlaceholder : ''"
      @change="handleChange($event.target.value)"
      @blur="$emit('blur')" />
    <template v-if="!hideErrorMessage">
      <small
        class="hidden group-data-[invalid=true]:block text-red-500 text-[0.875rem] mt-[0.25rem]">
        {{ errorMessage }}
      </small>
    </template>
  </div>
</template>

<script>
const Themes = {
  ROUNDED: 'rounded',
};
</script>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import IMask from 'imask';

const emit = defineEmits(['update:modelValue', 'blur']);

const props = defineProps({
  modelValue: { type: [String, Number], default: '' },
  modelModifiers: { type: Object, default: () => ({}) },
  label: { type: String, required: true },
  id: { type: String, default: '' },
  type: { type: String, default: 'text' },
  customClass: { type: String, default: '' },
  inputClasses: { type: String, default: '' },
  fixedLabel: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  dirty: { type: Boolean, default: false },
  errorMessage: { type: String, default: '' },
  maxLength: { type: Number, default: null },
  mask: { type: [String, Number, Array, RegExp], default: '' },
  maskOptions: { type: Object, default: () => ({}) },
  hideErrorMessage: { type: Boolean, default: false },
  theme: {
    type: String,
    default: '',
    validator: (value) => ['', ...Object.values(Themes)].includes(value),
  },
});

const inputPlaceholder = computed(() =>
  props.required ? `${props.label}*` : props.label
);

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    if (!props.modelModifiers.lazy) {
      emit('update:modelValue', value);
    }
  },
});

function handleChange(value) {
  inputValue.value =
    props.maxLength && value.length > props.maxLength
      ? value.slice(0, props.maxLength)
      : value;
  if (props.modelModifiers.lazy) {
    emit('update:modelValue', value);
  }
}

// IMask setup
const input = ref(null);
let imaskInstance = null;

function setupIMask() {
  if (!props.mask) return;

  imaskInstance = IMask(input.value, {
    mask: props.mask,
    ...props.maskOptions,
  });

  imaskInstance.value = props.modelValue;

  if (!props.modelModifiers.lazy) {
    imaskInstance.on('accept', () => {
      emit('update:modelValue', imaskInstance.unmaskedValue);
    });
  }
}

// Update IMask instance when prop modelValue changes
watch(
  () => props.modelValue,
  () => {
    if (imaskInstance) {
      imaskInstance.unmaskedValue = props.modelValue;
    }
  }
);

onMounted(setupIMask);
</script>

<style scoped>
.input {
  @apply w-full
  h-[3.5rem]
  px-[1.25rem]
  bg-ford-gray-light-2
  border
  border-ford-gray-light
  text-ford-gray-dark
  text-[1.125rem]
  rounded-lg
  outline-offset-4
  placeholder:text-ford-gray-dark
  group-data-[invalid=true]:border-red-500;
}
[data-theme='rounded'] .input {
  @apply h-[2.75rem]
  rounded-full
  text-[1rem]
  placeholder-[#BABABA]
  bg-white
  border-[#E0E0E0CC];
}
.input--textarea {
  @apply h-[10rem]
  py-[1.25rem];
}
</style>
