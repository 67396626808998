import apiManager from '@/api';

export default {
  namespaced: true,
  state() {
    return {
      countedTestDrives: {},
      csvTestDrives: "",
      dealershipHolidays: [],
      dealershipOperationHours: [],
      dealershipVehicles: [],
      isLoadingTestDrives: true,
      testDriveList: [],
    };
  },
  getters: {
    countedTestDrives: (state) => state.countedTestDrives,
    csvTestDrives: (state) => state.csvTestDrives,
    isLoadingTestDrives: (state) => state.isLoadingTestDrives,
    testDriveList: (state) => state.testDriveList,
    dealershipHolidays: (state) => state.dealershipHolidays,
    dealershipVehicles: (state) => state.dealershipVehicles,
    dealershipOperationHours: (state) => state.dealershipOperationHours,
  },
  mutations: {
    setIsLoadingTestDrives(state, value) {
      state.isLoadingTestDrives = value;
    },
    setCSVTestDrives(state, value) {
      state.csvTestDrives = value;
    },
    setTestDriveList(state, value) {
      state.testDriveList = value;
      this.commit('testDrive/setCountedTestDrives', value);
    },
    setDealershipHolidays(state, value) {
      state.dealershipHolidays = value;
    },
    setDealershipOperationHours(state, value) {
      state.dealershipOperationHours = value;
    },
    setDealershipVehicles(state, value) {
      state.dealershipVehicles = value;
    },
    setCountedTestDrives(state, value) {
      const countedTestDrives = {
        scheduled: value.filter((testDrive) => testDrive.status === 'Scheduled').length,
        reScheduled: value.filter((testDrive) => testDrive.status === 'Scheduled' && testDrive.reScheduled).length,
        completed: value.filter((testDrive) => testDrive.status === 'Completed').length,
        cancelled: value.filter((testDrive) => testDrive.status === 'Cancelled').length
      }

      state.countedTestDrives = countedTestDrives;
    }
  },
  actions: {
    async loadDealershipHoursOfOperation({ commit, rootGetters }) {
      const [
        { data: operationHours },
        { data: holidays },
      ] = await Promise.all([
        apiManager.hoursOfOperation.getByDealershipId(rootGetters['auth/dealershipId']),
        apiManager.hoursOfOperation.getDealershipHolidays(rootGetters['auth/dealershipId']),
      ]);

      commit('setDealershipOperationHours', operationHours);
      commit('setDealershipHolidays', holidays);
    },
    async loadDealershipVehicles({ commit, rootGetters }) {
      const { data } = await apiManager.vehicle.getDealershipVehicles(
        rootGetters['auth/dealershipId'],
      );

      commit('setDealershipVehicles', data);
    },
    async loadTestDrives({ commit, rootGetters }) {
      await apiManager.testDrive.getDealershipTestDrives(
        rootGetters['auth/dealershipId'],
      ).then((testDrive) => {
        const sortedData = testDrive.data.sort(function(a,b){
          return new Date(a.startTime) - new Date(b.startTime);
        });

        commit('setTestDriveList', sortedData);
      });

    },
    async loadCSVTestDrives({ commit, rootGetters }) {
      const response = await apiManager.testDrive.getTestDrivesCSVByDealership(
        rootGetters['auth/dealershipId']
      );

      const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
      
      commit('setCSVTestDrives', response.data);
      return {data: response.data, filename};
    }
  }
};
