export const MILEAGE_MASK = [
  { mask: '' },
  {
    mask: 'num Miles',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ',',
        scale: 0,
        max: 999999,
      },
    },
  },
];

export const TestDriveStatus = {
  LEAD: 'Lead',
  SCHEDULED: 'Scheduled',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
};

export const CREATE_TEST_DRIVE_STATUS_OPTIONS = [
  { text: 'Lead', value: TestDriveStatus.LEAD },
  { text: 'Scheduled', value: TestDriveStatus.SCHEDULED },
];

export const EDIT_TEST_DRIVE_STATUS_OPTIONS = [
  { text: 'Lead', value: TestDriveStatus.LEAD },
  { text: 'Scheduled', value: TestDriveStatus.SCHEDULED },
  { text: 'Completed', value: TestDriveStatus.COMPLETED },
  { text: 'Cancelled', value: TestDriveStatus.CANCELLED },
];

export const LeadSource = {
  QUALITY_LEAD: 'Quality Lead',
  DEALER: 'Dealer',
  EVENT: 'Event',
};

export const LEAD_SOURCE_OPTIONS = [
  { text: 'Quality Lead', value: LeadSource.QUALITY_LEAD },
  { text: 'Dealer', value: LeadSource.DEALER },
  { text: 'Event', value: LeadSource.EVENT },
];

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const TIME_OPTIONS_INTERVAL_IN_MINUTES = 30;

export const TestDriveTabs = {
  SHOW_ALL: {
    id: 'show-all',
    elementId: 'test-drive-tab--show-all',
    name: 'Show All',
  },
  LEAD: {
    id: TestDriveStatus.LEAD,
    elementId: 'test-drive-tab--lead',
    name: 'Lead',
  },
  SCHEDULED: {
    id: TestDriveStatus.SCHEDULED,
    elementId: 'test-drive-tab--scheduled',
    name: 'Scheduled',
  },
  COMPLETED: {
    id: TestDriveStatus.COMPLETED,
    elementId: 'test-drive-tab--completed',
    name: 'Completed',
  },
  CANCELLED: {
    id: TestDriveStatus.CANCELLED,
    elementId: 'test-drive-tab--canceled',
    name: 'Cancelled',
  },
}
