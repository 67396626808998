import * as validators from '@vuelidate/validators';

export const requiredValidator = validators.helpers.withMessage(
  'Please fill out this field',
  validators.required,
);

export const customRequiredValidator = (validator, message) => validators.helpers.withMessage(
  message || 'Please fill out this field',
  validator,
);

export const emailValidator = validators.helpers.withMessage(
  'Please provide a valid email address',
  validators.email,
);

export const zipCodeLengthValidator = validators.helpers.withMessage(
  'Please provide a five or nine digit zip code',
  (zipCode) => [5, 9].includes(zipCode.length),
);
  
export const phoneNumberLengthValidator = validators.helpers.withMessage(
  'Please provide a ten digit phone number',
  validators.minLength(10),
);
