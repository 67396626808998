<template :lang="'en'">
  <component :is="route.meta.layout" /> <vue3-snackbar top right
  :duration="4000"></vue3-snackbar>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { Vue3Snackbar } from 'vue3-snackbar';

const route = useRoute();

document.documentElement.lang = 'en';
</script>

<style lang="scss">
// Could claim this as a global style area.
</style>
