<template>
  <FullCalendar :options="calendarOptions" />
  <TestDriveModal
    :is-open="testDriveModalIsOpen"
    :test-drive="selectedTestDrive"
    @test-drive-added="loadTestDrives"
    @test-drive-updated="loadTestDrives"
    @close="closeTestDriveModal" />
</template>

<script setup>
import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';
import { createPopper } from '@popperjs/core';
import { format } from 'date-fns';
import FullCalendar from '@fullcalendar/vue3';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { TestDriveStatus } from '@/constants/testDrive';
import TestDriveModal from '../modals/TestDriveModal.vue';
import { appInsights } from '../../../application-insights';

const store = useStore();
const snackbar = useSnackbar();

// Selected dealership
const dealership = computed(() => store.getters['auth/dealership']);

// Selected test drive
const selectedTestDrive = ref(null);

// Test drive modal
const testDriveModalIsOpen = ref(false);

const closeTestDriveModal = () => {
  testDriveModalIsOpen.value = false;
  selectedTestDrive.value = null;
};

async function loadTestDrives() {
  store.commit('testDrive/setIsLoadingTestDrives', true);

  try {
    await store.dispatch('testDrive/loadTestDrives');
  } catch (error) {
    snackbar.add({
      type: 'error',
      text: error.message,
    });
  } finally {
    store.commit('testDrive/setIsLoadingTestDrives', false);
  }
}

const isCalendarTrigged = ref(false);

// Calendar options
const calendarBaseOptions = reactive({
  contentHeight: 'auto',
  plugins: [resourceTimelinePlugin, interactionPlugin],
  initialView: 'resourceTimelineDay',
  displayEventTime: false,
  slotDuration: '00:30:00',
  schedulerLicenseKey: '0588850945-fcs-1669066481',
  buttonText: {
    today: 'Today',
  },
  views: {
    resourceTimelineDay: { buttonText: 'Day' },
    resourceTimelineWeek: { buttonText: 'Week' },
    resourceTimelineMonth: { buttonText: 'Month' },
  },
  resourceAreaHeaderContent: '',
  resourceLabelContent: function (arg) {
    const title = arg.resource.title;
    const trim = arg.resource.extendedProps.trim;
    const color = arg.resource.extendedProps.color;
    const vin = arg.resource.extendedProps.vin;

    const html = `
			<div class="resource-title text-ford-black-light">${title} <span class="resource-trim">${trim}</span></div>
			<span class="resource-color text-ford-black-light">${color}</span>
			<div class="resource-vin text-ford-black-light">${vin}</div>
		`;

    return { html };
  },
  selectable: true,
  eventInteractive: true,
  datesSet: (date) => {
    !isCalendarTrigged.value
      ? (isCalendarTrigged.value = true)
      : trackEventOnButton('DatesChange');
  },
  dateClick: (data) => {
    selectedTestDrive.value = {
      ...selectedTestDrive.value,
      startTime: data.dateStr,
      status: TestDriveStatus.SCHEDULED,
      vehicleId: data.resource._resource.id,
      contactMethod: 'email',
    };
    testDriveModalIsOpen.value = true;
    trackEventOnButton('ClickCalendarToCreate');
  },
  eventClick: (info) => {
    selectedTestDrive.value = info.event.extendedProps.testDrive;
    testDriveModalIsOpen.value = true;
  },
  eventMouseEnter: (info) => {
    const eventElement = info.el;
    const event = info.event;

    // Use format function to format start and end dates
    const formattedStartDate = format(
      new Date(event.start),
      'EEEE, MMMM dd h:mm a'
    );
    const formattedEndDate = format(
      new Date(event.end),
      'EEEE, MMMM dd h:mm a'
    );

    const popoverElement = document.createElement('div');
    popoverElement.classList.add('popover-container');

    popoverElement.innerHTML = `
			<div class="popover-arrow" id="arrow" data-popper-arrow></div>
			<div class="popover-header">${event.title}</div>
			<div class="popover-body">
				Start: ${formattedStartDate}<br>
				End: ${formattedEndDate}
			</div>
		`;

    const popperInstance = createPopper(eventElement, popoverElement, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
      ],
    });

    popoverElement.style.zIndex = '9999';

    document.body.appendChild(popoverElement);

    eventElement.popperInstance = popperInstance;
  },
  eventMouseLeave: (info) => {
    const eventElement = info.el;

    if (eventElement.popperInstance) {
      eventElement.popperInstance.destroy();
      eventElement.popperInstance = null;
    }

    const poppers = Array.from(
      document.getElementsByClassName('popover-container')
    );
    poppers.map((popper) => document.body.removeChild(popper));
  },
});

const calendarOptions = computed(() => {
  const filteredTestDrives = store.getters['testDrive/testDriveList'].filter(
    (item) =>
      ![TestDriveStatus.LEAD, TestDriveStatus.CANCELLED].includes(item.status)
  );

  const isOnboarded = dealership.value.onBoarded;

  const customButtons = isOnboarded
    ? {
        addTestDriveButton: {
          text: '+ Add Test Drive',
          click: function () {
            testDriveModalIsOpen.value = true;

            trackEventOnButton('AddTestDriveButton');
          },
        },
      }
    : {
        addTestDriveButtonDisabled: {
          text: '+ Add Test Drive',
          hint : 'Functionality will be enabled once the dealership is fully onboarded.'
        },
      };

  const headerToolbar = {
    left: 'today prev,next',
    center: 'title',
    right: `resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth ${
      isOnboarded ? 'addTestDriveButton' : 'addTestDriveButtonDisabled'
    }`,
  };

  return {
    ...calendarBaseOptions,
    customButtons,
    headerToolbar,
    resources: store.getters['testDrive/dealershipVehicles']
      .filter((vehicle) => !!vehicle.active)
      .map((vehicle) => ({
        id: vehicle.vehicleId,
        title: vehicle.modelYear,
        trim: vehicle.trim,
        color: vehicle.color,
        vin: vehicle.vin,
      })),
    events: filteredTestDrives.map((item) => ({
      title: `${item.firstName} ${item.lastName}`,
      start: item.startTime,
      end: item.endTime,
      resourceId: item.vehicle.vehicleId,
      color:
        item.status === TestDriveStatus.COMPLETED
          ? 'rgba(76, 175, 80, 0.9)'
          : 'rgba(6, 111, 239, 0.9)',
      extendedProps: {
        testDrive: item,
      },
    })),
    eventDidMount: (item) => {
      const element = item.el;
      const info = item.event.extendedProps.testDrive;
      const formattedDate = (date) => format(new Date(date), "MM/dd/yyyy 'at' hh:mm aa");
      
      const ariaLabel = `Test drive for ${info.firstName} ${info.lastName} starting on ${formattedDate(info.startTime)} and ending on ${formattedDate(info.endTime)}`;
      element.setAttribute('aria-label', ariaLabel);
      element.setAttribute('title', ariaLabel);
    },
  };
});
function trackEventOnButton(action) {
  appInsights.trackEvent({
    name: 'TestDriveCalendarActions',
    properties: {
      dealership: dealership.value.dealerName,
      action: action,
      page: 'dashboard',
    },
  });
}
</script>

<style lang="scss">
.fc .fc-toolbar-title {
  @apply font-FordF1Med text-ford-blue-dark text-3xl uppercase;
}

.fc .fc-button-primary {
  @apply text-lg text-ford-gray-dark bg-transparent;

  &.fc-button-primary:not(:disabled).fc-button-active,
  &:hover {
    @apply bg-ford-blue-dark;
  }
}

.fc-button.fc-today-button {
  @apply rounded-full py-1 px-4 border-ford-gray-light;

  &:disabled {
    @apply bg-ford-blue-dark opacity-100;
  }
}

.fc-button-group .fc-button.fc-button-primary {
  @apply border-ford-gray-light py-1 px-4;

  &:first-child {
    @apply rounded-l-full;
  }

  &:last-child {
    @apply rounded-r-full;
  }
}

.fc-button.fc-addTestDriveButton-button {
  @apply bg-ford-blue-light border-transparent rounded-full text-white py-1 px-4;
}
.fc-button.fc-addTestDriveButtonDisabled-button {
  @apply bg-ford-gray-dark cursor-not-allowed #{!important};
  @apply border-transparent rounded-full text-white py-1 px-4;
}

.fc th {
  @apply font-FordF1Med font-normal text-sm uppercase;
}

.fc .fc-resource,
.fc .fc-scrollgrid {
  @apply border-none;
}

.fc-resource {
  .fc-datagrid-cell-frame {
    @apply flex flex-col justify-center;

    .fc-datagrid-cell-cushion {
      @apply flex px-5 h-16 items-center bg-[#ECECEC] rounded-l-full;

      .resource-title {
        @apply flex font-FordF1Bold uppercase leading-[0.25em];

        .resource-trim {
          @apply ml-1;
        }
      }

      .resource-color {
        @apply text-xs font-FordF1Med;
      }

      .resource-vin {
        @apply text-sm font-FordF1Med leading-[0.5em];
      }
    }
  }
}

.fc .fc-event {
  @apply h-12 px-2.5 text-base uppercase font-FordF1Lt tracking-wide rounded-[0.625rem] hover:cursor-pointer;
}

.fc-timeline-event:not(.fc-event-end)::after,
.fc-timeline-event:not(.fc-event-start)::before {
  border-color: transparent rgb(255, 255, 255);
  opacity: 0.8;
}

.popover-container {
  @apply bg-ford-blue-translucent text-white p-3 rounded-lg shadow-lg relative;

  .popover-arrow {
    @apply absolute
		w-0
		h-0
		left-1/2
		transform
		-bottom-2
		border-8
		border-transparent;
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -6px;
    @apply border-b-0
		border-t-ford-blue-translucent;
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -8px;
    @apply -translate-x-1/2
		-rotate-180
		border-t-0
		border-b-ford-blue-translucent;
  }

  .popover-header {
    @apply font-bold mb-2.5;
  }
}
</style>
