import {
  ref,
  computed,
  watch,
} from 'vue';
import { ITEMS_PER_PAGE_OPTIONS } from '@/constants/table';

export function useTable(tableItems, searchKeys = []) {
  // Search
  const searchTerm = ref('');

  const filteredItems = computed(() => tableItems.value.filter((item) => {
    if (searchKeys.length === 0 || searchTerm.value === '') return true;

    let found = false;

    searchKeys.forEach((key) => {
      if (found) return;
      found = String(item[key]).toLocaleLowerCase().includes(searchTerm.value);
    });

    return found;
  }));

  // Pagination
  const itemsPerPage = ref(ITEMS_PER_PAGE_OPTIONS[0].value);
  const currentPage = ref(1);

  const paginatedItems = computed(() => filteredItems.value.slice(
    (currentPage.value - 1) * itemsPerPage.value,
    currentPage.value * itemsPerPage.value,
  ));

  const totalOfPages = computed(() => Math.ceil(
    filteredItems.value.length / itemsPerPage.value,
  ));

  function moveToPreviousPage() {
    currentPage.value -= 1;
  }

  function moveToNextPage() {
    currentPage.value += 1;
  }

  watch(() => totalOfPages.value, () => {
    if (currentPage.value === 0) {
      currentPage.value = 1;
      return;
    }
    if (currentPage.value > totalOfPages.value) {
      currentPage.value = totalOfPages.value;
    }
  });

  return {
    items: filteredItems,
    searchTerm,
    itemsPerPage,
    currentPage,
    paginatedItems,
    totalOfPages,
    moveToPreviousPage,
    moveToNextPage,
  };
}
