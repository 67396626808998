import { baseAPI } from './baseApi';

export default class Authenticate extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Auth');
  }

  getLoginUrl() {
    return this.axiosObject.get(`${this.apiBaseName}/Ford/LoginURL`);
  }

  getAccessToken(authCode) {
    return this.axiosObject.post(`${this.apiBaseName}/Ford/Token`, {
      authCode,
    });
  }
}
