<template>
  <header class="header bg-ford-blue-dark">
    <div class="h-2 w-full bg-ford-blue-light"></div>
    <Disclosure
      as="nav"
      v-slot="{ open }">
      <div class="max-w-[100rem] mx-auto px-4">
        <div class="flex h-[4.25rem] justify-between text-lg">
          <div class="flex">
            <router-link
              to="/"
              class="flex flex-shrink-0 items-center">
              <img
                class="h-10 w-auto"
                src="@/assets/img/ford-oval-logo.svg"
                alt="Ford oval brand Logo" />
            </router-link>
            <div class="hidden md:ml-6 md:flex md:space-x-12">
              <router-link
                v-for="link in navLinks"
                :key="link.name"
                :to="link.path"
                :active-class="'font-FordF1Bold'"
                class="inline-flex items-center px-1 text-white">
                {{ link.name }}
              </router-link>
            </div>
          </div>
          <div
            v-if="!!dealership"
            class="hidden md:ml-6 md:flex md:items-center">
            <!-- Profile dropdown -->
            <Menu
              as="div"
              class="relative">
              <MenuButton
                class="relative flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="absolute -inset-1.5" />
                <span class="sr-only">Open user menu</span>
                <div class="text-white">
                  {{ dealership?.dealerName }}
                </div>
                <img
                  class="h-[1.875rem] w-[1.875rem] mx-3 rounded-full"
                  src="@/assets/img/default-user-img.svg"
                  alt="user profile avatar" />
                <img
                  src="@/assets/img/dropdown-icon.svg"
                  alt="" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem
                    v-slot="{ active }"
                    v-if="!!admin">
                    <button
                      @click="navigateToSwitchDealership"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block w-full px-4 py-2 text-sm text-left',
                      ]">
                      Dealership Selection
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="navigateToLogOut"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block w-full px-4 py-2 text-sm text-left',
                      ]">
                      Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="-mr-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="relative inline-flex items-center justify-center p-2 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true" />
              <XMarkIcon
                v-else
                class="block h-6 w-6"
                aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="space-y-1 pb-3 pt-2">
          <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
          <!-- <DisclosureButton as="a" href="#" class="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700">Dashboard</DisclosureButton>
                    <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Team</DisclosureButton>
                    <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Projects</DisclosureButton>
                    <DisclosureButton as="a" href="#" class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">Calendar</DisclosureButton> -->
          <router-link
            v-for="(link, index) in navLinks"
            :key="index"
            :to="link.path">
            <DisclosureButton
              as="div"
              class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700">
              {{ link.name }}
            </DisclosureButton>
          </router-link>
        </div>
        <div class="border-t border-gray-200 pb-3 pt-4">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img
                class="h-[1.875rem] w-[1.875rem] rounded-full"
                src="@/assets/img/default-user-img.svg"
                alt="user profile avatar" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">
                {{ dealership?.dealerName }}
              </div>
              <div class="text-sm font-medium text-gray-500">
                {{ dealership?.email }}
              </div>
            </div>
          </div>
          <div
            class="mt-3 space-y-1"
            v-if="!!admin">
            <DisclosureButton
              as="div"
              @click="navigateToSwitchDealership"
              class="block px-4 py-2 text-base font-medium cursor-pointer text-white hover:bg-gray-100 hover:text-gray-800"
              >Dealership Selection
            </DisclosureButton>
          </div>
          <div class="mt-3 space-y-1">
            <DisclosureButton
              as="a"
              href="#"
              class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
              >Sign out
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import navLinks from '@/data/navLinks';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { appInsights } from '../../../application-insights';

const store = useStore();
const router = useRouter();

const dealership = computed(() => store.getters['auth/dealership']);
const admin = computed(() => store.getters['auth/admin']);

const navigateToLogOut = () => {
  const currentPath = window.location.pathname.split('/').pop();
  appInsights.trackEvent({
    name: 'LogoutButtonClick',
    properties: { dealership: dealership.value.dealerName, page: currentPath },
  });
  router.push('/logout')
};

const navigateToSwitchDealership = () => {
  const currentPath = window.location.pathname.split('/').pop();
  appInsights.trackEvent({
    name: 'SwitchDealershipButtonClick',
    properties: { dealership: dealership.value.dealerName, page: currentPath },
  });
  router.push('/select-dealership');
};
</script>
