import { format, set, addMinutes, isAfter } from 'date-fns';
import { TIME_OPTIONS_INTERVAL_IN_MINUTES } from '@/constants/testDrive';

const getDateFromRawTime = (rawTime, date) => {
  const [time, period] = rawTime.split(' ');
  const [hour, minutes] = time.split(':');

  return set(date || new Date(), {
    hours: (period === 'PM' && hour !== '12') ? +hour + 12 : +hour,
    minutes,
  });
}

export function getTimeDropdownOptions({ startTime, endTime }) {
  const endDate = getDateFromRawTime(endTime);
  let startDate = getDateFromRawTime(startTime);

  const options = [];

  while (!isAfter(startDate, endDate)) {
    options.push({
      text: format(startDate, 'hh:mm a'),
      value: format(startDate, 'hh:mm a'),
    });
  
    startDate = addMinutes(startDate, TIME_OPTIONS_INTERVAL_IN_MINUTES);
  }

  return options;
}

export function createTimestampFromDateAndTime({ date, time }) {
  if (!date) return '';

  return (time)
    ? getDateFromRawTime(time, date)
    : set(date, { hours: 0, minutes: 0 });
}
