<template>
  <section class="mb-8">
    <dashboard-container-box
      title="Test Drive Overview"
      :hasGradientBg="false">
      <div class="flex justify-between">
        <DashboardItem
          title="Completed Test Drives"
          :value="countedTestDrives.completed"
          no-border />
        <DashboardItem
          title="Scheduled Test Drives"
          :value="countedTestDrives.scheduled" />
        <DashboardItem
          title="Rescheduled Test Drives"
          :value="countedTestDrives.reScheduled" />
        <DashboardItem
          title="Cancelled Test Drives"
          :value="countedTestDrives.cancelled" />
      </div>
    </dashboard-container-box>
  </section>

  <section class="mb-8">
    <dashboard-container-box title="Test Drive Calendar">
      <appointment-calendar />
    </dashboard-container-box>
  </section>

  <section>
    <dashboard-container-box title="Test Drive Records">
      <DashboardTestDrives />
    </dashboard-container-box>
  </section>
</template>

<script setup>
import { onMounted, onUnmounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';

import DashboardContainerBox from './DashboardContainerBox.vue';
import AppointmentCalendar from '@/components/dashboard/AppointmentCalendar.vue';
import DashboardItem from '@/components/dashboard/DashboardItem.vue';
import DashboardTestDrives from '@/components/dashboard/DashboardTestDrives.vue';

import { appInsights } from '../../../application-insights';

const snackbar = useSnackbar();
const store = useStore();

const dealership = computed(() => store.getters['auth/dealership']);
const countedTestDrives = computed(
  () => store.getters['testDrive/countedTestDrives']
);

const fetchDealershipOperationHours = () => {
  store.dispatch('testDrive/loadDealershipHoursOfOperation');
};

const fetchDealershipVehicles = () => {
  store.dispatch('testDrive/loadDealershipVehicles');
};

const loadTestDrives = async (autoRefresh = false) => {
  !autoRefresh && store.commit('testDrive/setIsLoadingTestDrives', true);

  try {
    await store.dispatch('testDrive/loadTestDrives');
  } catch (error) {
    snackbar.add({
      type: 'error',
      text: error.message,
    });
  } finally {
    store.commit('testDrive/setIsLoadingTestDrives', false);
  }
};

const refreshInterval = ref(null);

const autoRefresh = () => {
  refreshInterval.value = setInterval(() => {
    fetchDealershipOperationHours();
    fetchDealershipVehicles();
    loadTestDrives(true);
  }, 10000);
};

onMounted(() => {
  fetchDealershipOperationHours();
  fetchDealershipVehicles();

  appInsights.trackPageView({
    name: `DashboardPage`,
    properties: { dealership: dealership.value.dealerName, page: 'dashboard' },
  });

  loadTestDrives();

  autoRefresh();
});

onUnmounted(() => {
  clearInterval(refreshInterval.value);
});
</script>
