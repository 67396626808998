import { baseAPI } from './baseApi';

export default class HoursOfOperation extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, 'HoursOfOperation');
  }

  getByDealershipId(dealershipId) {
    return this.axiosObject.get(`${this.apiBaseName}/GetByDealershipId`, {
      params: { dealershipId },
    });
  }

  getDealershipHolidays(dealershipId) {
    return this.axiosObject.get(`Holidays/${dealershipId}`);
  }
}
