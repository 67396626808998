<template>
  <div class="table-pagination">
    <span class="text-ford-gray-dark">
      Showing
      <b>{{ pageFirstItem < 0 ? 0 : pageFirstItem }}</b> to
      <b>{{ pageLastItem }}</b> of {{ totalOfItems }} Entries
    </span>
    <div>
      <AppButton
        :is-loading="loadingCSV"
        :disabled="loadingCSV"
        @click="fetchCSVData">
        Export Data
      </AppButton>
    </div>
    <div class="flex items-center gap-[2rem]">
      <div class="flex items-center gap-[0.5rem]">
        <button
          class="table-pagination__button"
          type="button"
          :disabled="currentPage < 2"
          aria-label="Previous Page"
          @click="changePage('previous-page')">
          <span class="fc-icon fc-icon-chevron-left" />
        </button>
        <button
          v-for="n in pageNumbers"
          v-text="n"
          class="table-pagination__page-button"
          type="button"
          :key="n"
          :data-active="n === currentPage"
          @click="changePage('set-page', n)" />
        <button
          class="table-pagination__button"
          type="button"
          :disabled="currentPage === totalOfPages"
          aria-label="Next Page"
          @click="changePage('next-page', n)">
          <span class="fc-icon fc-icon-chevron-right" />
        </button>
      </div>
      <AppSelect
        v-model="inputValue"
        id="table-pagination--items-per-page"
        is-small
        is-black
        aria-label="Choose items per page"
        :options="ITEMS_PER_PAGE_OPTIONS" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';
import { ITEMS_PER_PAGE_OPTIONS } from '@/constants/table';
import AppButton from './form/AppButton.vue';
import AppSelect from './form/AppSelect.vue';
import { appInsights } from '../../application-insights';

const snackbar = useSnackbar();
const store = useStore();

const emit = defineEmits([
  'update:items-per-page',
  'previous-page',
  'next-page',
  'set-page',
]);
const props = defineProps({
  totalOfItems: {
    type: Number,
    required: true,
  },
  totalOfPages: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: true,
  },
  appInsightsConfig: {
    type: Object,
    required: false,
  },
});

const dealershipId = computed(() => store.getters('auth/dealershipId'));

const inputValue = computed({
  get: () => props.itemsPerPage,
  set(value) {
    trackEventOnTable('ItemsPerPage', value);
    emit('update:items-per-page', value);
  },
});

const loadingCSV = ref(false);

const pageNumbers = computed(() => {
  let pages = [];
  let counter = 1;

  while (counter <= props.totalOfPages) {
    pages.push(counter);
    counter += 1;
  }

  return pages;
});

const pageFirstItem = computed(
  () => (props.currentPage - 1) * props.itemsPerPage + 1
);
const pageLastItem = computed(() =>
  props.currentPage === props.totalOfPages
    ? props.totalOfItems
    : props.currentPage * props.itemsPerPage
);

const fetchCSVData = async () => {
  loadingCSV.value = true;
  trackEventOnTable('ExportData');
  try {
    const response = await store.dispatch('testDrive/loadCSVTestDrives');

    const blob = new Blob([response.data], { type: 'text/csv' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = response.filename.replace(/"/g, '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    snackbar.add({
      type: 'error',
      text: error.message,
    });
  } finally {
    loadingCSV.value = false;
  }
};

const changePage = (action, pageNumber = null) => {
  action === 'set-page' ? emit(action, pageNumber) : emit(action);

  const value = action === 'set-page' ? pageNumber : action;
  trackEventOnTable('ChangePage', value);
};

const trackEventOnTable = (action, value = '') => {
  appInsights.trackEvent({
    name: props.appInsightsConfig.name,
    properties: {
      dealership: props.appInsightsConfig.dealershipName,
      action: action,
      page: props.appInsightsConfig.page,
      actionValue: value,
    },
  });
};
</script>

<style scoped>
.select {
  @apply text-ford-black-light;
}
.table-pagination {
  @apply flex justify-between items-center mt-[1rem];
}
.table-pagination__button {
  @apply inline-grid
  place-items-center
  w-[1.75rem]
  h-[1.75rem]
  text-ford-black-light
  disabled:text-ford-gray-dark;
}
.table-pagination__button span {
  @apply text-[1.5rem];
}
.table-pagination__page-button {
  @apply flex
  justify-center
  items-center
  w-[1.75rem]
  h-[1.75rem]
  text-ford-black-light
  data-[active="true"]:underline
  data-[active="true"]:font-bold;
}
</style>
