import axios from 'axios';
import Authenticate from './authenticate';
import TestDrive from './testDrive';
import Dealership from './dealership';
import Vehicle from './vehicle';
import Message from "./message";
import HoursOfOperation from './hoursOfOperation';
import router from "@/router";

const success = response => response;
const failure = (error) => {
  if (!error.response) {
    return error;
  }

  if (error.response.status === 401) {
    if (error.response.data?.message?.startsWith('Dealer not enrolled')) {
      router.push({ name: 'not-enrolled' });
    } else {
      router.push('/logout')
    }
  }

  if (error.response.status === 403) {
    router.push('/logout')
  }

  throw error;
};

class ApiManager {
  constructor() {
    this.axiosObject = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_URL,
    });

    this.axiosObject.interceptors.response.use(success, failure);
    this.authenticate = new Authenticate(this.axiosObject);
    this.testDrive = new TestDrive(this.axiosObject);
    this.dealership = new Dealership(this.axiosObject);
    this.vehicle = new Vehicle(this.axiosObject);
    this.message = new Message(this.axiosObject);
    this.hoursOfOperation = new HoursOfOperation(this.axiosObject);
  }

  setAuthHeader(authToken) {
    if (!authToken) {
      delete this.axiosObject.defaults.headers.common.Authorization;
    } else {
      this.axiosObject.defaults.headers.common.Authorization = authToken;
    }
  }
}

export default new ApiManager();
