<template>
  <AppModal
    id="test-drive-modal"
    :title="isEditingTestDrive ? 'Edit Test Drive' : 'Create New Test Drive'"
    :show="isOpen"
    @close="$emit('close')">
    <p class="mb-[0.75rem] text-right">*Required</p>
    <div class="grid grid-cols-2 gap-x-[1rem] gap-y-[0.75rem] mb-[1.75rem]">
      <AppInput
        v-model.lazy="form.firstName"
        id="test-drive--first-name"
        required
        label="First Name"
        :dirty="v$.firstName.$dirty"
        :error-message="v$.firstName.$errors[0]?.$message" />
      <AppInput
        v-model.lazy="form.lastName"
        id="test-drive--last-name"
        required
        label="Last Name"
        :dirty="v$.lastName.$dirty"
        :error-message="v$.lastName.$errors[0]?.$message" />
      <AppInput
        v-model.lazy="form.address"
        id="test-drive--address"
        required
        label="Street Address"
        :dirty="v$.address.$dirty"
        :error-message="v$.address.$errors[0]?.$message" />
      <div class="grid gap-x-[0.625rem] grid-cols-3">
        <AppInput
          v-model.lazy="form.city"
          id="test-drive--city"
          required
          label="City"
          :dirty="v$.city.$dirty"
          :error-message="v$.city.$errors[0]?.$message" />
        <AppSelect
          v-model.lazy="form.state"
          id="test-drive--state"
          required
          label="State"
          aria-label="State"
          :options="STATE_OPTIONS"
          :dirty="v$.state.$dirty"
          :error-message="v$.state.$errors[0]?.$message" />
        <AppInput
          v-model.lazy="form.zipCode"
          id="test-drive--zip-code"
          required
          label="Zip Code"
          mask="00000-0000"
          :dirty="v$.zipCode.$dirty"
          :error-message="v$.zipCode.$errors[0]?.$message" />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-x-[1rem] gap-y-[0.75rem]">
      <AppInput
        v-model.lazy="form.email"
        id="test-drive--email"
        required
        label="Email Address"
        :dirty="v$.email.$dirty"
        :error-message="v$.email.$errors[0]?.$message" />
      <AppInput
        v-model.lazy="form.phoneNumber"
        id="test-drive--phone-number"
        required
        label="Phone Number"
        mask="(000) 000-0000"
        :dirty="v$.phoneNumber.$dirty"
        :error-message="v$.phoneNumber.$errors[0]?.$message" />
    </div>
    <!-- <div class="my-[2.5rem]">
      <p>Preferred Contact Method:*</p>
      <p class="text-red-600">Text message communications are currently unavailable. Functionality is coming soon!</p>
      <div class="flex gap-[4rem] mt-[1rem]">
        <AppRadioInput
          v-model="form.contactMethod"
          id="test-drive--contact-text"
          disabled
          text="Text"
          value="text"
        />
        <AppRadioInput
          v-model="form.contactMethod"
          aria-checked="true"
          id="test-drive--contact-email"
          text="Email"
          value="email"
        />
        <AppRadioInput
          v-model="form.contactMethod"
          id="test-drive--contact-both"
          disabled
          text="Both"
          value="both"
        />
      </div>
      <small
        v-show="v$.contactMethod.$dirty && !!v$.contactMethod.$errors[0]?.$message"
        class="block text-red-500 text-[0.875rem] mt-[0.25rem]"
      >
        {{ v$.contactMethod.$errors[0]?.$message }}
      </small>
    </div> -->
    <!-- The class mt-[2.5rem] in the DIV below should be removed to return the design to the correct way -->
    <div
      class="grid grid-cols-4 gap-x-[1rem] gap-y-[0.75rem] mb-[1.75rem] mt-[2.5rem]">
      <AppDatePicker
        v-model="form.startDate"
        placeholder="Choose Start Date"
        :enable-time-picker="false"
        :disabled="form.testDriveStatus === TestDriveStatus.LEAD"
        :disabled-dates="calendarDisabledDates"
        :disabled-week-days="calendarDisabledWeekdays"
        :dirty="v$.startDate.$dirty"
        :error-message="v$.startDate.$errors[0]?.$message" />
      <AppSelect
        v-model="form.startTime"
        id="test-drive--start-time"
        label="Choose Start Time"
        aria-label="Choose Start Time"
        :disabled="!form.startDate"
        :required="!testDriveStatusIsLead"
        :options="startTimeOptions"
        :dirty="v$.startTime.$dirty"
        :error-message="v$.startTime.$errors[0]?.$message" />
      <AppDatePicker
        v-model="form.endDate"
        placeholder="Choose End Date"
        :enable-time-picker="false"
        :min-date="endDateMinDate"
        :disabled="form.testDriveStatus === TestDriveStatus.LEAD"
        :disabled-week-days="calendarDisabledWeekdays"
        :dirty="v$.endDate.$dirty"
        :error-message="v$.endDate.$errors[0]?.$message" />
      <AppSelect
        v-model="form.endTime"
        id="test-drive--end-time"
        label="Choose End Time"
        aria-label="Choose End Time"
        :disabled="!form.endDate"
        :required="!testDriveStatusIsLead"
        :options="endTimeOptions"
        :dirty="v$.endTime.$dirty"
        :error-message="v$.endTime.$errors[0]?.$message" />
      <AppSelect
        v-model="form.vehicle"
        id="test-drive--vehicle"
        class="col-span-4"
        label="Choose Vehicle"
        aria-label="Choose Vehicle"
        :required="!testDriveStatusIsLead"
        :options="vehicleOptions"
        :disabled="vehicleOptions.length === 0"
        :dirty="v$.vehicle.$dirty"
        :error-message="v$.vehicle.$errors[0]?.$message" />
    </div>
    <div class="grid grid-cols-2 gap-x-[1rem] gap-y-[0.75rem] mb-[1.75rem]">
      <AppSelect
        v-model="form.testDriveStatus"
        id="test-drive--status"
        required
        label="Status"
        aria-label="Status"
        :options="testDriveStatusOptions"
        :dirty="v$.testDriveStatus.$dirty"
        :error-message="v$.testDriveStatus.$errors[0]?.$message" />
      <AppSelect
        v-model="form.leadSource"
        id="test-drive--lead"
        required
        label="Lead Source"
        aria-label="Lead Source"
        :options="leadSourceOptions"
        :dirty="v$.leadSource.$dirty"
        :error-message="v$.leadSource.$errors[0]?.$message" />
    </div>
    <div class="grid grid-cols-2 gap-x-[1rem] gap-y-[0.75rem]">
      <AppInput
        v-model.lazy="form.startMileage"
        id="test-drive--start-mileage"
        label="Start Mileage"
        :mask="MILEAGE_MASK"
        :dirty="v$.startMileage.$dirty"
        :error-message="v$.startMileage.$errors[0]?.$message" />
      <AppInput
        v-model.lazy="form.endMileage"
        id="test-drive--end-mileage"
        label="End Mileage"
        :mask="MILEAGE_MASK"
        :dirty="v$.endMileage.$dirty"
        :error-message="v$.endMileage.$errors[0]?.$message" />
    </div>
    <AppCheckbox
      v-model="form.optIn"
      id="test-drive--opt-in"
      class="my-[2.5rem]"
      text="Received verbal opt-in from Driver" />
    <AppInput
      v-model="form.notes"
      id="test-drive--notes"
      label="Notes"
      type="textarea" />
    <template #footer>
      <AppButton
        :is-loading="isLoading"
        @click="submitForm">
        {{ !isEditingTestDrive ? '+ Add Test Drive' : '+ Save Changes' }}
      </AppButton>
    </template>
  </AppModal>
</template>

<script setup>
import { ref, computed, watch, watchEffect, nextTick } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { useSnackbar } from 'vue3-snackbar';
import { format, getDay, isAfter, isEqual } from 'date-fns';
import { Loader } from '@googlemaps/js-api-loader';
import {
  TestDriveStatus,
  MILEAGE_MASK,
  CREATE_TEST_DRIVE_STATUS_OPTIONS,
  EDIT_TEST_DRIVE_STATUS_OPTIONS,
  WEEKDAYS,
} from '@/constants/testDrive';
import { STATE_OPTIONS } from '@/constants/states';
import {
  requiredValidator,
  customRequiredValidator,
  emailValidator,
  zipCodeLengthValidator,
  phoneNumberLengthValidator,
} from '@/helpers/validators';
import {
  getTimeDropdownOptions,
  createTimestampFromDateAndTime,
} from '@/helpers/testDriveCalendar';
import api from '@/api';
import AppModal from './AppModal.vue';
import AppButton from '../form/AppButton.vue';
import AppInput from '../form/AppInput.vue';
import AppSelect from '../form/AppSelect.vue';
import AppRadioInput from '../form/AppRadioInput.vue';
import AppDatePicker from '../form/AppDatePicker.vue';
import AppCheckbox from '../form/AppCheckbox.vue';
import { appInsights } from '../../../application-insights';
import { isBefore } from 'date-fns';

const store = useStore();
const snackbar = useSnackbar();

const emit = defineEmits(['close', 'test-drive-added', 'test-drive-updated']);
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  allDay: {
    type: Boolean,
    default: false,
  },
  testDrive: {
    type: Object,
    default: null,
  },
});

const dealership = computed(() => store.getters['auth/dealership']);
const dealershipOperationHours = computed(
  () => store.getters['testDrive/dealershipOperationHours']
);

// Form fields
const form = ref({
  testDriveId: null,
  pauseWatchers: false,
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  email: '',
  phoneNumber: '',
  contactMethod: 'email',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  vehicle: '',
  testDriveStatus: '',
  leadSource: '',
  startMileage: '',
  endMileage: '',
  optIn: false,
  notes: '',
});
const leadSourceOptions = ref([]);

const startDateAndTime = computed(() =>
  createTimestampFromDateAndTime({
    date: form.value.startDate,
    time: form.value.startTime,
  })
);

const endDateAndTime = computed(() =>
  createTimestampFromDateAndTime({
    date: form.value.endDate,
    time: form.value.endTime,
  })
);

const testDriveStatusIsLead = computed(
  () => form.value.testDriveStatus === TestDriveStatus.LEAD
);
const testDriveStatusIsCompleted = computed(
  () => form.value.testDriveStatus === TestDriveStatus.COMPLETED
);

const isEditingTestDrive = computed(() => !!props.testDrive);

// Validation rules
const startDateValidator = customRequiredValidator(
  () => testDriveStatusIsLead.value || !!form.value.startDate
);
const startTimeValidator = customRequiredValidator(
  () =>
    testDriveStatusIsLead.value ||
    !form.value.startDate ||
    !!form.value.startTime
);
const endDateRequiredValidator = customRequiredValidator(
  () => testDriveStatusIsLead.value || !!form.value.endDate
);
const endDateValueValidator = customRequiredValidator(
  () =>
    !form.value.startDate ||
    !form.value.endTime ||
    (form.value.endDate &&
      isAfter(endDateAndTime.value, startDateAndTime.value)),
  'The End day/time should be greater than the Start day/time'
);
const endTimeValidator = customRequiredValidator(
  () =>
    testDriveStatusIsLead.value || !form.value.endDate || !!form.value.endTime
);
const vehicleValidator = customRequiredValidator(
  () => testDriveStatusIsLead.value || !!form.value.vehicle
);
const startMileageValidator = customRequiredValidator(
  () => !testDriveStatusIsCompleted.value || !!form.value.startMileage,
  'Start Mileage is required when completing a test drive'
);
const endMileageValueValidator = customRequiredValidator(
  () =>
    !form.value.endMileage || +form.value.endMileage > +form.value.startMileage,
  'End Mileage must be a larger number than Start Mileage'
);

const v$ = useVuelidate(
  {
    firstName: { required: requiredValidator },
    lastName: { required: requiredValidator },
    address: { required: requiredValidator },
    city: { required: requiredValidator },
    state: { required: requiredValidator },
    zipCode: { required: requiredValidator, zipCodeLengthValidator },
    email: { required: requiredValidator, emailValidator },
    phoneNumber: { required: requiredValidator, phoneNumberLengthValidator },
    contactMethod: { required: requiredValidator },
    startDate: { startDateValidator },
    startTime: { startTimeValidator },
    endDate: { endDateRequiredValidator, endDateValueValidator },
    endTime: { endTimeValidator },
    vehicle: { vehicleValidator },
    testDriveStatus: { required: requiredValidator },
    leadSource: { required: requiredValidator },
    startMileage: { startMileageValidator },
    endMileage: { endMileageValueValidator },
  },
  form,
  { $autoDirty: true }
);

// Test Drive status options
const testDriveStatusOptions = computed(() => {
  const options = isEditingTestDrive.value
    ? EDIT_TEST_DRIVE_STATUS_OPTIONS
    : CREATE_TEST_DRIVE_STATUS_OPTIONS;

  return !form.value.endDate && !form.value.startDate
    ? options
    : options.filter((status) => status.value !== TestDriveStatus.LEAD);
});

// Calendar options
const endDateMinDate = computed(() => form.value.startDate);
const calendarDisabledDates = computed(
  () => store.getters['testDrive/dealershipHolidays']
);
const calendarDisabledWeekdays = computed(() =>
  dealershipOperationHours.value
    .filter((day) => day.startTime === 'Closed')
    .map(({ day }) => WEEKDAYS.findIndex((weekday) => weekday === day))
);

function getDealershipOperationDay(date) {
  if (!date) return;

  const weekday = WEEKDAYS[getDay(date)];
  return dealershipOperationHours.value.find(({ day }) => day === weekday);
}

const startTimeOptions = computed(() => {
  const day = getDealershipOperationDay(form.value.startDate);

  if (!day) return [];

  return getTimeDropdownOptions({
    startTime: day.startTime,
    endTime: day.endTime,
  });
});

watch(
  () => form.value.startDate,
  () => {
    if (form.value.pauseWatchers) return;

    form.value.startTime = '';
    form.value.endDate = '';
  }
);

const endTimeOptions = computed(() => {
  const day = getDealershipOperationDay(form.value.endDate);

  if (!day) return [];

  return getTimeDropdownOptions({
    startTime: day.startTime,
    endTime: day.endTime,
  });
});

watch(
  () => form.value.endDate,
  () => {
    if (form.value.pauseWatchers) return;

    form.value.endTime = '';
  }
);

// Reset form
function resetForm() {
  form.value.testDriveId = null;
  form.value.firstName = '';
  form.value.lastName = '';
  form.value.address = '';
  form.value.city = '';
  form.value.state = '';
  form.value.zipCode = '';
  form.value.email = '';
  form.value.phoneNumber = '';
  form.value.contactMethod = 'email';
  form.value.startDate = '';
  form.value.startTime = '';
  form.value.endDate = '';
  form.value.endTime = '';
  form.value.vehicle = '';
  form.value.testDriveStatus = '';
  form.value.leadSource = '';
  form.value.startMileage = '';
  form.value.endMileage = '';
  form.value.optIn = false;
  form.value.notes = '';

  v$.value.$reset();
}

// Load form
async function loadTestDriveIntoForm() {
  if (!props.testDrive) return;

  form.value.pauseWatchers = true;

  form.value.testDriveId = props.testDrive.testDriveId;
  form.value.firstName = props.testDrive.firstName;
  form.value.lastName = props.testDrive.lastName;
  form.value.address = props.testDrive.address;
  form.value.city = props.testDrive.city;
  form.value.state = props.testDrive.state;
  form.value.zipCode = props.testDrive.zip?.replaceAll('-', '');
  form.value.email = props.testDrive.email;
  form.value.phoneNumber = props.testDrive.phone;
  form.value.contactMethod = props.testDrive.contactMethod;
  form.value.startDate = new Date(props.testDrive.startTime);
  form.value.startTime = !props.allDay
    ? format(new Date(props.testDrive.startTime), 'hh:mm a')
    : '';
  form.value.endDate = new Date(props.testDrive.endTime);
  form.value.endTime = props.testDrive.endTime
    ? format(new Date(props.testDrive.endTime), 'hh:mm a')
    : '';
  form.value.testDriveStatus = props.testDrive.status;
  form.value.leadSource = props.testDrive.leadSource;
  form.value.startMileage = props.testDrive.startMileage
    ? String(props.testDrive.startMileage)
    : '';
  form.value.endMileage = props.testDrive.endMileage
    ? String(props.testDrive.endMileage)
    : '';
  form.value.optIn = props.testDrive.optIn;
  form.value.notes = props.testDrive.notes;

  v$.value.$reset();

  await nextTick();

  form.value.pauseWatchers = false;
  form.value.vehicle = props.testDrive.vehicleId;
}

// Vehicle options
const vehicleOptions = ref([]);

watchEffect(async () => {
  vehicleOptions.value = [];

  if (
    !form.value.startDate ||
    !form.value.endDate ||
    !form.value.startTime ||
    !form.value.endTime ||
    !isAfter(endDateAndTime.value, startDateAndTime.value)
  )
    return;

  let { data } = await api.vehicle.getAvailableVehicles({
    testDriveId: form.value.testDriveId,
    dealershipId: store.getters['auth/dealershipId'],
    startDate: startDateAndTime.value,
    endDate: endDateAndTime.value,
  });

  if (
    isAfter(startDateAndTime.value, new Date()) ||
    isAfter(endDateAndTime.value, new Date())
  ) {
    data = data?.filter((vehicle) => vehicle.active);
  }

  vehicleOptions.value = data.map((item) => ({
    text: `${item.modelYear} ${item.trim} ${item.color} (${item.vin})`,
    value: item.vehicleId,
  }));

  if (!props.testDrive) return;

  const { vehicle, startTime, endTime } = props.testDrive;

  if (
    isEqual(new Date(startTime), startDateAndTime.value) &&
    isEqual(new Date(endTime), endDateAndTime.value)
  ) {
    vehicleOptions.value.push({
      text: `${vehicle.modelYear} ${vehicle.trim} ${vehicle.color} (${vehicle.vin})`,
      value: vehicle.vehicleId,
    });
  }
});

// Google Maps API
let autocompleteService = null;

function onPlaceChanged() {
  const { formatted_address: formattedAddress } =
    autocompleteService.getPlace();

  if (!formattedAddress) return;

  // Reset address fields
  form.value.address = '';
  form.value.city = '';
  form.value.state = '';
  form.value.zipCode = '';

  const [name, cityName, stateAddress] = formattedAddress.split(',');
  const [stateInitials, zipCodeValue] = stateAddress.trim().split(' ');

  form.value.address = name;
  form.value.city = cityName.trim();
  form.value.state = stateInitials.length > 2 ? '' : stateInitials;
  form.value.zipCode = zipCodeValue?.trim() || '';

  v$.value.$reset();
}

async function loadGoogleMapsAPI() {
  const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  const { Autocomplete } = await loader.importLibrary('places');

  autocompleteService = new Autocomplete(
    document.querySelector('#test-drive--address'),
    {
      componentRestrictions: { country: ['US'] },
      types: ['address'],
      fields: ['formatted_address'],
    }
  );

  autocompleteService.addListener('place_changed', onPlaceChanged);
}

async function loadDropdownData() {
  const { data } = await api.dealership.getDropdownsValues();
  leadSourceOptions.value = data
    .filter((value) => value.dropdownType === 'LeadSource' && !!value.active)
    .map((option) => ({
      text: option.dropdownText,
      value: option.dropdownValue,
    }));
}

// On mounted
watch(
  () => props.isOpen,
  () => {
    if (!props.isOpen) return;

    resetForm();
    loadTestDriveIntoForm();
    loadGoogleMapsAPI();
    loadDropdownData();
  }
);

// Submit form
const isLoading = ref(false);

async function submitForm() {
  if (!await v$.value.$validate()) return;

  if (
    process.env.VUE_APP_BASE_ENVIRONMENT === 'production' ||
    process.env.VUE_APP_BASE_ENVIRONMENT === 'preprod'
  ) {
    const programStartDate = new Date('2024', '01', '05');
    if (isBefore(startDateAndTime.value, programStartDate)) {
      snackbar.add({
        type: 'error',
        text: 'Cannot schedule test drives before 2/5/2024.',
      });
      return;
    }
  }

  const programEndNextDate = new Date('2024', '04', '06');
  if (isAfter(endDateAndTime.value, programEndNextDate)) {
    snackbar.add({
      type: 'error',
      text: 'The test drive must end on 5/5/2024 or earlier.',
    });
    return;
  }

  isLoading.value = true;
  try {
    await api.testDrive.createOrUpdateTestDrive({
      testDriveId: form.value.testDriveId,
      dealershipId: store.getters['auth/dealershipId'],
      vehicleId: form.value.vehicle,
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      address: form.value.address,
      city: form.value.city,
      state: form.value.state,
      zipCode: form.value.zipCode,
      email: form.value.email,
      phoneNumber: form.value.phoneNumber,
      contactMethod: form.value.contactMethod,
      optIn: form.value.optIn,
      leadSource: form.value.leadSource,
      startTime: startDateAndTime.value,
      endTime: endDateAndTime.value,
      status: form.value.testDriveStatus,
      startMileage: +form.value.startMileage,
      endMileage: +form.value.endMileage,
      notes: form.value.notes,
    });

    if (isEditingTestDrive.value) {
      snackbar.add({
        type: 'success',
        text: 'Success! The test drive was updated.',
      });

      emit('test-drive-updated');
    } else {
      snackbar.add({
        type: 'success',
        text: 'Success! The test drive was created.',
      });

      emit('test-drive-added');
    }

    appInsights.trackEvent({
      name: `TestDrive${isEditingTestDrive.value ? 'Edited' : 'Created'}`,
      properties: {
        dealership: dealership.value.dealerName,
        page: 'test-drive-modal',
      },
    });

    emit('close');
  } catch (error) {
    snackbar.add({
      type: 'error',
      text: error.message,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>
