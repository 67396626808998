import { createStore } from 'vuex';
import authModule from './modules/auth';
import testDriveModule from './modules/testDrive';

export default createStore({
  modules: {
    auth: authModule,
    testDrive: testDriveModule,
  },
});
