<template>
  <div
    class="group w-fit"
    :class="{ 'is-invalid': dirty && !!errorMessage }"
    :data-checked="isChecked"
    :data-invalid="dirty && !!errorMessage"
    :data-disabled="disabled"
  >
    <label
      class="checkbox__label"
      :for="id"
    >
      <input
        v-model="inputValue"
        class="opacity-0 w-0 h-0 absolute"
        type="checkbox"
        :id="id"
        :value="value"
        :disabled="disabled"
      />
      <div :class="'checkbox__control ' + customClass">
        <IconCheck class="hidden group-data-[checked=true]:block w-[1.5rem]" />
      </div>
      <div class="flex-1 transition-opacity group-data-[disabled=true]:opacity-50">
        <slot>
          <span
            v-html="text"
            class="checkbox__text"
          />
        </slot>
      </div>
    </label>
    <small class="hidden group-data-[invalid=true]:block text-red-500 text-[0.875rem] mt-[0.25rem]">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import IconCheck from '../icons/IconCheck.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: [Boolean, Array],
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  customClass: {
    type: String,
    default: '',
  },
  dirty: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
});

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});

const isChecked = computed(() => (
  (typeof inputValue.value === 'boolean')
    ? inputValue.value
    : inputValue.value.includes(props.value)
));
</script>

<style scoped>
.checkbox__control {
  @apply
  grid
  place-items-center
  w-[2rem]
  h-[2rem]
  shrink-0
  bg-ford-gray-light-2
  rounded-lg
  border
  border-ford-gray-light
  cursor-pointer
  group-data-[disabled=true]:cursor-not-allowed
  group-data-[disabled=true]:opacity-50
}
.checkbox__label {
  @apply flex gap-[1rem];
}
.checkbox__text {
  @apply flex h-full items-center leading-[1];
}
.group input:focus-visible + .checkbox__control {
  @apply outline outline-1 outline-offset-4;
}
</style>
