<template>
  <div
    class="date-picker group"
    :class="{ 'is-invalid': dirty && !!errorMessage }"
    :data-invalid="dirty && !!errorMessage"
    :data-theme="theme">
    <div class="relative w-full">
      <IconCalendar
        class="absolute top-[50%] translate-y-[-50%] right-[1rem] z-[1] w-[1.25rem] pointer-events-none" />
      <VueDatePicker
        v-model="inputValue"
        v-bind="$attrs"
        input-class-name="app-date-picker"
        auto-apply
        hide-input-icon
        text-input
        :aria-labels="ariaLabels"
        :clearable="false"
        :is-24="false"
        :format="formatDate"
        @update-month-year="updateAriaLabels" />
    </div>
    <small
      class="hidden group-data-[invalid=true]:block text-red-500 text-[0.875rem] mt-[0.25rem]">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script>
const Themes = {
  ROUNDED: 'rounded',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import IconCalendar from '../icons/IconCalendar.vue';
import { format, isDate } from 'date-fns';
import '@vuepic/vue-datepicker/dist/main.css';

import { MONTHS } from '@/constants/testDrive';

const emit = defineEmits(['update:model-value']);
const props = defineProps({
  modelValue: {
    type: [String, Date],
    default: '',
  },
  dirty: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  theme: {
    type: String,
    default: '',
    validator: (value) => ['', ...Object.values(Themes)].includes(value),
  },
});

const ariaLabels = ref({
  openYearsOverlay: `Open years overlay. ${new Date().getFullYear()} currently selected.`,
  openMonthsOverlay: `Open months overlay. ${MONTHS[new Date().getMonth()]} currently selected.`,
});

const updateAriaLabels = (e) => {
  const { month, year } = e;

  ariaLabels.value = {
    openYearsOverlay: `Open years overlay. ${year} currently selected.`,
    openMonthsOverlay: `Open months overlay. ${MONTHS[month]} currently selected.`,
  };
};

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:model-value', value);
  },
});

function formatDate(date) {
  return isDate(date) ? format(date, 'MM/dd/yyyy') : '';
}
</script>

<style>
.date-picker .app-date-picker {
  @apply w-full
  h-[3.5rem]
  pl-[1.25rem]
  pr-[3rem]
  bg-ford-gray-light-2
  border
  border-ford-gray-light
  text-ford-gray-dark
  text-[1rem]
  font-FordF1Reg
  rounded-lg
  outline-offset-4;
}

.date-picker[data-invalid='true'] .app-date-picker {
  @apply border-red-500;
}

.date-picker[data-theme='rounded'] .app-date-picker {
  @apply h-[2.75rem]
  rounded-full
  text-[1rem]
  bg-white
  border-[#E0E0E0CC];
}
</style>
