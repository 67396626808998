<template>
  <div class="relative flex flex-col min-h-screen background overflow-hidden">
    <div class="background-2" />
    <main class="relative flex-1 overflow-hidden flex items-center">
      <div class="ml-[110px] my-auto w-[710px] text-white space-y-4">
        <img
          class="h-20 w-auto"
          src="@/assets/img/ford-oval-logo.svg"
          alt="Ford oval brand Logo" />
        <p class="text-[90px] leading-[100px]">You Don’t Seem To Be Enrolled</p>
        <p class="w-[680px] text-[32px]">
          The Ford EV Extended Test Drive Program is currently only available to
          a select number of dealers. Please contact your zone manager to
          determine eligibility.
        </p>
      </div>
    </main>
    <app-footer :disable-nav="true" />
  </div>
</template>

<script setup>
import AppFooter from '@/components/layout/AppFooter.vue';
import { onMounted, computed } from 'vue';
import { appInsights } from '../../application-insights';
import { useStore } from 'vuex';

const store = useStore();
const dealership = computed(() => store.getters['auth/dealership']);

onMounted(() => {
  appInsights.trackPageView({name: 'NotEnrolledPage', properties: {dealership: dealership.value.dealerName, page: 'not-enrolled'}});
});
</script>

<style scoped>
.background {
  background-image: url('../assets/img/not-enrolled-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.background-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 55%;
  right: 0;
  width: 2551px;
  height: calc(100% - 128px);
  object-fit: contain;
  background: url('../assets/img/not-enrolled-background-2.jpg') no-repeat
    center;
}
</style>
