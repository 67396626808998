import { createApp } from 'vue';
import { SnackbarService } from 'vue3-snackbar';
import 'vue3-snackbar/styles';

import App from './App.vue';
import router from './router';
import store from './store';
import Heading from '@/components/layout/AppPageHeading.vue';
import './main.css';


createApp(App)
  .component('Heading', Heading)
  .use(store)
  .use(router)
  .use(SnackbarService)
  .mount('#app');
