import { format } from 'date-fns';
import { baseAPI } from './baseApi';

export default class Vehicle extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Vehicle');
  }

  getDealershipVehicles(dealershipId) {
    return this.axiosObject.get(`${this.apiBaseName}/GetByDealershipId`, {
      params: { dealershipId },
    });
  }

  getAvailableVehicles({ testDriveId, dealershipId, startDate, endDate }) {
    return this.axiosObject.get(`${this.apiBaseName}/Available`, {
      params: {
        testDriveId,
        dealershipId,
        startTime: format(startDate, 'MM-dd-yyyy HH:mm:ss'),
        endTime: format(endDate, 'MM-dd-yyyy HH:mm:ss'),
      },
    });
  }
}