import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.VUE_APP_BASE_ENVIRONMENT !== 'production'
        ? 'a078c306-95be-4260-b11f-52118f4dc619'
        : '92f174dd-3ee2-432d-859b-c726c875dcc9',
  },
});

appInsights.loadAppInsights();

export { appInsights };
