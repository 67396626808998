import { baseAPI } from './baseApi';

export default class Message extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Message');
  }

  async getAll() {
    try {
      const response = await this.axiosObject.get(this.apiBaseName);
      const { data } = response;
      return { ...response, data, success: true };
    }
    catch (error) {
      return { ...error.repsonse, success: false };
    }
  }
  submitHelpInquiry(payload) {
    return this.axiosObject.post(this.apiBaseName, {
      dealershipId: payload.dealershipId,
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      subject: payload.subject,
      description: payload.description,
    });
  }
}
