import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '../layouts/DefaultLayout.vue';
import PhqResourcesLayout from '../layouts/PhqResourcesLayout.vue';
import DashboardView from '../views/dashboard/DashboardView.vue';
import { useStore } from 'vuex';
import NotEnrolled from '@/views/NotEnrolled.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';

const DEFAULT_META_TITLE = 'Ford Extended Test Drive';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      title: 'Dashboard',
      pageHeading: 'Dashboard',
      layout: DefaultLayout,
      requiresAuth: true,
    }
  },
  {
    path: '/pre-drive-survey/:testDriveId',
    name: 'pre-drive-survey',
    component: () => import(/* webpackChunkName: "test-drive-survey" */ '../views/surveys/PreTestDriveSurveyView.vue'),
    meta: {
      title: 'Pre Drive Survey',
      pageHeading: 'Test Drive Survey',
      layout: BlankLayout,
    }
  },
  {
    path: '/post-drive-survey/:testDriveId',
    name: 'post-drive-survey',
    component: () => import(/* webpackChunkName: "test-drive-survey" */ '../views/surveys/PostTestDriveSurveyView.vue'),
    meta: {
      title: 'Post Drive Survey',
      pageHeading: 'Test Drive Survey',
      layout: BlankLayout,
    }
  },
  {
    path: '/survey-complete',
    name: 'survey-complete',
    component: () =>
      import(
        /* webpackChunkName: "test-drive-survey" */ '../views/surveys/SubmittedSurveyView.vue'
      ),
    meta: {
      title: 'Survey Complete',
      pageHeading: 'Survey Complete',
      layout: BlankLayout,
    },
  },
  {
    path: '/phq-help',
    name: 'phq-help',
    component: () => import(/* webpackChunkName: "phq-help" */ '../views/PhqHelpView.vue'),
    meta: {
      title: 'PHQ Help',
      pageHeading: 'PHQ Help',
      pageSubhead: `
        Need help? Submit a message to PHQ and we will respond within one business day. <br /> <span class="inline-block mt-3">You can also call us at <a class="underline" href="tel:8555402631">855-540-2631</a> M-F 9am – 5pm EST.</span>
      `,
      layout: PhqResourcesLayout,
      requiresAuth: true,
    }
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "resources" */ '../views/ResourcesView.vue'),
    meta: {
      title: 'Resources',
      pageHeading: 'Resources',
      pageSubhead: 'Here you can find helpful documents for getting onboarded and running the program.',
      layout: PhqResourcesLayout,
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      title: 'Login',
      layout: BlankLayout,
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/LogOutView.vue'),
    meta: {
      title: 'Logout',
      layout: DefaultLayout,
    }
  },
  {
    path: '/select-dealership',
    name: 'select-dealership',
    component: () => import(/* webpackChunkName: "select-dealership" */ '../views/SelectDealershipView.vue'),
    meta: {
      title: 'Select Dealership',
      layout: DefaultLayout,
      requiresAuth: true,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    // redirect: '/',
    beforeEnter: (to, from, next) => {
      const whereTo = to.path === '/pre-drive-survey' || to.path === '/post-drive-survey' ? next('/survey-complete') : next('/');
     return whereTo;
    }
  },
  {
    path: '/not-enrolled',
    name: 'not-enrolled',
    component: NotEnrolled,
    meta: {
      title: 'Not Enrolled',
      pageTitle: 'Not Enrolled',
      description: 'Ford Extended Test Drive - Not Enrolled',
      layout: BlankLayout,
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach(async (to) => {
  const store = useStore();

  await store.dispatch('auth/reloadUser');

  if (
    store.getters['auth/userIsAuthenticated'] &&
    !store.getters['auth/dealership'] &&
    to.name !== 'select-dealership'
  ) {
    return { name: 'select-dealership' };
  }
  if (
    (to.name === 'login' && store.getters['auth/userIsAuthenticated']) ||
    (to.name === 'select-dealership' && store.getters['auth/dealership'] && !store.getters['auth/admin'])
  ) {
    return { name: 'dashboard' };
  }
  if (to.meta.requiresAuth && !store.getters['auth/userIsAuthenticated']) {
    return { name: 'login' };
  }

  document.title = to.meta.title ? `${to.meta.title} - ${DEFAULT_META_TITLE}` : DEFAULT_META_TITLE;
});

export default router
