import Cookies from 'js-cookie';
import apiManager from '@/api';

export default {
  namespaced: true,
  state() {
    return {
      accessToken: null,
      dealership: null,
      admin: null,
    };
  },
  getters: {
    userIsAuthenticated: ({ accessToken }) => !!accessToken,
    admin: ({ admin }) => admin,
    dealership: ({ dealership }) => dealership,
    dealershipId: ({ dealership }) => dealership?.dealershipId || '',
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setAdmin(state, value) {
      state.admin = value;
    },
    setDealership(state, dealership) {
      state.dealership = dealership;
    },
  },
  actions: {
    async getAccessToken({ dispatch, commit }, authCode) {
      const { data: { token: accessToken, dealershipId } } = await apiManager.authenticate.getAccessToken(authCode);

      await dispatch('setSessionCookie', {
        accessToken,
        dealership: null,
        admin: !dealershipId
      });
      
      
      if (!dealershipId) return;
      
      const { data: dealership } = await apiManager.dealership.getDealershipById(dealershipId);
      
      await dispatch('setSessionCookie', {
        accessToken,
        dealership,
        admin: false
      });      
    },
    setSessionCookie({ commit }, cookie) {
      Cookies.remove(process.env.VUE_APP_SESSION_COOKIE);

      if (!cookie) {
        apiManager.setAuthHeader(null);
        commit('setAccessToken', null);
        commit('setAdmin', false);
        commit('setDealership', null);

        return;
      }

      apiManager.setAuthHeader(cookie.accessToken);
      commit('setAccessToken', cookie.accessToken);
      commit('setAdmin', cookie.admin);
      commit('setDealership', cookie.dealership);

      Cookies.set(process.env.VUE_APP_SESSION_COOKIE, JSON.stringify(cookie));
    },
    async setUserDealership({ dispatch }, dealership) {
      const cookie = Cookies.get(process.env.VUE_APP_SESSION_COOKIE);

      await dispatch('setSessionCookie', {
        ...JSON.parse(cookie),
        dealership,
      });
    },
    async reloadUser({ dispatch }) {
      const cookie = Cookies.get(process.env.VUE_APP_SESSION_COOKIE);
  
      if (cookie) {
        await dispatch('setSessionCookie', JSON.parse(cookie));
      }
    },
  },
};