<template>
  <Teleport to="body">
    <div
      v-if="show"
      class="modal__overlay"
    >
      <div
        class="modal__container"
        aria-modal="true"
        role="dialog"
        :aria-labelledby="`${id}--title`"
      >
        <header class="modal__header">
          <span
            v-text="title"
            :id="`${id}--title`"
            class="modal__title"
          />
          <div>
            <slot name="header-controls" />
          </div>
        </header>
        <slot />
        <div class="modal__footer">
          <AppButton
            color="outline"
            @click="$emit('close')"
          >
            Cancel
          </AppButton>
          <slot name="footer" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { watchEffect } from 'vue';
import AppButton from '../form/AppButton.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

// Handle modal open and close events
watchEffect(() => {
  (props.show) ? handleOpenModal() : handleCloseModal();
});

function handleKeyupEvent(event) {
  if (event.key === 'Escape') {
    emit('close');
  }
}

function handleOpenModal() {
  document.body.classList.add('overflow-hidden');
  document.body.addEventListener('keyup', handleKeyupEvent);
  document.querySelector('#app')?.setAttribute('inert', '');
}

function handleCloseModal() {
  document.body.classList.remove('overflow-hidden');
  document.body.removeEventListener('keyup', handleKeyupEvent);
  document.querySelector('#app')?.removeAttribute('inert');
}
</script>

<style scoped>
.modal__overlay {
  @apply
  grid
  py-[4rem]
  px-[2rem]
  lg:py-[8rem]
  bg-[#00142ED1]
  fixed
  inset-0
  z-50
  overflow-y-auto;
  place-items: start center;
}
.modal__container {
  @apply
  flex
  flex-col
  w-full
  max-w-[68rem]
  min-h-[30rem]
  py-[2rem]
  px-[1.5rem]
  bg-white
  rounded-2xl;
}
.modal__header {
  @apply
  flex
  justify-between
  flex-wrap
  gap-[1.25rem]
  mb-[1.75rem];
}
.modal__title {
  @apply
  text-[1.25rem]
  lg:text-[1.75rem]
  text-ford-blue-dark
  uppercase;
}
.modal__footer {
  @apply
  flex
  justify-end
  gap-[1rem]
  mt-auto
  pt-[1.75rem];
}
</style>
