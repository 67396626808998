import { baseAPI } from './baseApi';

export default class Dealership extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Dealership');
  }

  async getAll() {
    try {
      const response = await this.axiosObject.get(this.apiBaseName);
      const { data } = response;
      return { ...response, data, success: true };
    }
    catch (error) {
      return { ...error.repsonse, success: false };
    }
  }

  getDealershipById(dealershipId) {
    return this.axiosObject.get(`${this.apiBaseName}/GetByDealershipId`, {
      params: { dealershipId },
    });
  } 

  getDropdownsValues() {
    return this.axiosObject.get(`${this.apiBaseName}/GetAllDropdowns`, {
      params: { dealerSite: true }
    });
  } 
  getDropdownsValuesByWebSite(webSite) {
    return this.axiosObject.get(`${this.apiBaseName}/GetDropdownsByWebSite`, {
      params: { webSite }
    });
  } 

  getPromoTileByDealership(dealershipId) {
    return this.axiosObject.get(`${this.apiBaseName}/PromoTile/${dealershipId}`, {responseType: 'blob'});
  } 
}