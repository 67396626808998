<template>
  <div
    class="group"
    :class="{ 'is-invalid': dirty && !!errorMessage }"
    :data-invalid="dirty && !!errorMessage">
    <label v-if="fixedLabel">{{ inputPlaceholder }}</label>
    <div class="relative flex w-full items-center">
      <select
        v-model="inputValue"
        :class="'select ' + customClass"
        :disabled="disabled"
        :aria-label="ariaLabel"
        :data-small="isSmall"
        :data-black="isBlack">
        <option
          class="text-ford-gray-dark"
          disabled
          value="">
          {{fixedLabel ? inlineOption : inputPlaceholder}}
          </option>
        <option
          v-for="option in options"
          v-text="option.text || option"
          class="text-ford-gray-dark"
          :key="option.value || option"
          :value="option.value || option" />
      </select>
    </div>
    <small v-if="!hideErrorMessage"
      class="hidden group-data-[invalid=true]:block text-red-500 text-[0.875rem] mt-[0.25rem]">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: { type: [String, Number], default: '' },
  label: { type: String, default: '' },
  ariaLabel: { type: String, required: true },
  hideErrorMessage: { type: Boolean, required: false },
  inlineOption: { type: String, required: false },
  options: { type: Array, default: () => [] },
  fixedLabel: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  dirty: { type: Boolean, default: false },
  customClass: { type: String, default: '' },
  errorMessage: { type: String, default: '' },
  isSmall: { type: Boolean, default: false },
  isBlack: { type: Boolean, default: false },
});

const inputPlaceholder = computed(() =>
  props.required ? `${props.label}*` : props.label
);

const inputValue = computed({
  get: () => props.modelValue,
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped>
.select {
  @apply w-full
  h-[3.5rem]
  px-[1.25rem]
  pr-[2rem]
  bg-ford-gray-light-2
  border
  border-ford-gray-light
  text-ford-gray-dark
  text-[1.125rem]
  rounded-lg
  outline-offset-4
  placeholder:text-ford-gray-dark
  appearance-none
  cursor-pointer
  disabled:cursor-default
  group-data-[invalid=true]:border-red-500
  data-[black="true"]:text-ford-black-light
  data-[black="true"]:bg-[url('@/assets/img/chevron-down.svg')]
  data-[small="true"]:h-[2.5rem];
}
</style>
