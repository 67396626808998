
<template>
  <BaseButton
    class="button"
    :disabled="disabled"
    :to="to"
    :data-color="color"
    :data-loading="isLoading"
    @click="handleClick"
  >
    <IconSpinner
      v-if="isLoading"
      class="button__icon-loader"
      size="24"
    />
    <div
      v-else
      class="button__content">
      <slot />
    </div>
  </BaseButton>
</template>

<script>
const Colors = {
  PRIMARY: 'primary',
  OUTLINE: 'outline',
  DANGER: 'danger',
};
</script>

<script setup>
import BaseButton from './BaseButton.vue';
import IconSpinner from '../icons/IconSpinner.vue';

const emit = defineEmits(['click']);
const props = defineProps({
  to: {
    type: [String, Object],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: Colors.PRIMARY,
    validator: (value) => Object.values(Colors).includes(value),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

function handleClick(event) {
  if (props.isLoading) {
    event.preventDefault();
    return;
  }

  emit('click', event);
}
</script>

<style scoped>
.button {
  @apply
  min-w-[11.375rem]
  h-[2.875rem]
  px-[1.25rem]
  rounded-full
  text-[1.125rem]
  transition-all
  ease-in
  duration-300
  leading-[normal];
}
.button[data-color="primary"] {
  @apply
  bg-ford-blue-light
  border
  border-[#FFFFFFCC]
  text-white
  [&:not([data-loading="true"])]:hover:bg-ford-blue-accent;
  --loader-indicator-color: #fff;
}
.button[data-color="danger"] {
  @apply
  bg-ford-danger-medium
  border
  border-ford-danger-light
  text-white
  [&:not([data-loading="true"])]:hover:bg-ford-danger-light;
  --loader-indicator-color: #fff;
}
.button[data-color="outline"] {
  @apply
  border
  border-ford-gray-dark
  [&:not([data-loading="true"])]:hover:bg-slate-200;
  --loader-indicator-color: #717171;
}
.button[data-loading="true"] {
  cursor: default;
}
.button__icon-loader {
  @apply
  flex
  justify-center
  items-center;
  --track-color: transparent;
  --indicator-color: var(--loader-indicator-color);
}
</style>
