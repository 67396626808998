<template>
  <div
    class="flex items-end gap-[1.5rem] w-fit relative border-b-[#C9C9C9] border-b-[1px]"
    ref="tabPanel"
  >
    <label
      v-for="{ id, elementId, name } in tabs"
      class="
        px-[0.25rem]
        focus:bg-red
        pb-[0.5rem]
        relative
        whitespace-nowrap
        font-medium
        cursor-pointer
      "
      :key="id"
      :for="elementId || id"
      :data-id="id"
      :data-checked="activeTabId === id"
    >

      <input
        v-model="activeTabId"
        class="absolute inset-0 opacity-0 cursor-pointer"
        type="radio"
        :name="name"
        :id="elementId || id"
        :value="id"
        :aria-label="`Status ${name}`"
        @keypress.enter="$event.target.click()"
      />
      <span>{{ name }}</span>
    </label>
    <div
      class="
        w-[var(--width,_24px)]
        h-[0.1875rem]
        bg-ford-blue-light
        rounded-full
        transition-all
        absolute
        left-[var(--position,_0)]
        bottom-[-2px]
      "
      aria-hidden="true"
      :style="`--width: ${markerWidth}px; --position: ${markerPosition}px;`"
    />
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  onMounted,
  watch,
  onBeforeUnmount,
} from 'vue';

const emit = defineEmits(['update:active-tab-id']);
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  activeTabId: {
    type: [String, Number],
    required: true,
  },
});

const activeTabId = computed({
  get: () => props.activeTabId,
  set(value) {
    emit('update:active-tab-id', value);
  },
});

const tabPanel = ref(null);

const markerWidth = ref(0);
const markerPosition = ref(0);

function getMarkerStyles() {
  if (!tabPanel.value) return;

  const { left: tabPanelPosition } = tabPanel.value.getBoundingClientRect();

  const activeTab = tabPanel.value.querySelector(`[data-id=${activeTabId.value}]`);

  if (!activeTab) return;

  const { width, left: activeTabPosition } = activeTab.getBoundingClientRect();

  markerWidth.value = width;
  markerPosition.value = activeTabPosition - tabPanelPosition;
}

onMounted(getMarkerStyles);
watch(() => props.activeTabId, getMarkerStyles);
document.addEventListener('resize', getMarkerStyles);


onBeforeUnmount(() => document.removeEventListener('resize', getMarkerStyles));
</script>

<style>
label:focus-within {
  box-shadow: rgba(76, 91, 106, 0.5) 0px 0px 0px 0.2rem !important;  
}
</style>