import { format } from 'date-fns';
import { baseAPI } from './baseApi';
import { TestDriveStatus } from '@/constants/testDrive';

export default class TestDrive extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, 'TestDrive');
  }
  
  getDealershipTestDrives(dealershipId) {
    return this.axiosObject.get(`${this.apiBaseName}/GetByDealershipId`, {
      params: { dealershipId },
    });
  }

  getTestDrivesCSVByDealership(dealershipId) {
    return this.axiosObject.get(`${this.apiBaseName}/TestDriveDownload`, {
      params: { dealershipId },
      responseType: 'blob'
    });
  }

  createOrUpdateTestDrive(payload) {
    let testDriveObj = {
      testDriveId: payload.testDriveId || undefined,
      dealershipId: payload.dealershipId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      address: payload.address,
      city: payload.city,
      state: payload.state,
      zip: payload.zipCode,
      email: payload.email,
      phone: payload.phoneNumber,
      contactMethod: payload.contactMethod,
      optIn: payload.optIn,
      leadSource: payload.leadSource,
      status: payload.status,
      startMileage: payload.startMileage,
      endMileage: payload.endMileage,
      notes: payload.notes,
    };

    payload.startTime && (testDriveObj.startTime = format(payload.startTime, 'yyyy-MM-dd\'T\'HH:mm:ss'));
    payload.endTime && (testDriveObj.endTime = format(payload.endTime, 'yyyy-MM-dd\'T\'HH:mm:ss'));
    payload.status !== TestDriveStatus.LEAD && (testDriveObj.vehicleId = payload.vehicleId);

    return this.axiosObject.post(this.apiBaseName, testDriveObj);
  }

  
  getSurveyInfo(testDriveId) {
    return this.axiosObject.get(`${this.apiBaseName}/SurveyInfo/${testDriveId}`);
  }

  
  saveSurvey(surveyType, body) {
    return this.axiosObject.post(`${this.apiBaseName}/${surveyType}`, body);
  }
}